<template lang="html" src="./pageImageUpdate.template.vue"></template>

<script>

import { DownloadPartViewer3dFile, Viewer3d } from '@cloudmanufacturingtechnologies/portal-components';

/**
 * An empty component
 */
export default {
  name: 'PageImageUpdate',
  components: {
    Viewer3d
  },
  props: {},
  data() {
    return {
      brands: [],
      brand: null,
      brandIndex: null,
      parts: [],
      part: null,
      fullPartObject: null,
      partIndex: null,
      partsErrorImage: [],
      started: false,
      stopAtNext: false,
      viewer3dFile: null,
      viewerFullscreen: true,
      previousPartImageDataURL: null
    };
  },
  created() {
    this.getBrands();
  },
  methods: {
    start() {
      this.started = true;
      this.nextPart();
    },
    nextBrand() {
      if(this.brandIndex === null) {
        this.brandIndex = 0;
      } else {
        this.brandIndex++;
      }

      if(this.brandIndex >= this.brands.length) {
        // Finished
        return;
      }

      this.brand = this.brands[this.brandIndex];

      this.$apiInstance.getBrandParts(
        this.brand._id
      ).then(data => {
        this.parts = data;
        this.partIndex = null;
        this.nextPart();
      });
    },
    nextPart() {
      if(this.partIndex === null) {
        this.partIndex = 0;
      } else {
        this.partIndex++;
      }

      if(!this.brand || this.partIndex >= this.parts.length) {
        this.nextBrand();
        return;
      }

      this.part = this.parts[this.partIndex];

      if(this.part.brand_id !== this.brand._id || !!this.part.image) {
        this.nextPart();
        return;
      }

      this.$apiInstance.getAdminBrandPart(
        this.part._id
      ).then(data => {
        if(data.analysisStatus === 'DONE') {
          this.fullPartObject = data;
          this.getPartViewer3dFile();
        } else {
          this.nextPart();
        }
      });
    },
    renderingReady() {
      const self = this;
      setTimeout(() => {
        self.$refs.viewer3dRef.captureImage();
      }, 2000);
    },
    addBrandPartImage(data) {
      const file = new File([data], `${this.part.name}.png`, {
        type: 'image/png',
      });

      this.previousPartImageDataURL = URL.createObjectURL(file);

      this.$apiInstance.addBrandPartImage(
        this.part.brand_id,
        this.part._id,
        {file: file}
      ).then(data => {
        this.$notification.notify('SUCCESS', `Image de la pièce ${this.part.name} sauvegardé avec succès`);
      }, error => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        if(!this.stopAtNext) {
          this.nextPart();
        } else {
          this.stopAtNext = false;
          this.started = false;
        }
      });
    },
    getBrands() {
      this.$apiInstance.getAllBrands().then(data => {
        this.brands = data;
      });
    },
    getPartViewer3dFile() {
      this.viewer3dFile = null;
      const oReq = DownloadPartViewer3dFile.downloadPartViewer3dFile(
        this.$apiInstance,
        this.part.brand_id,
        this.part._id,
        new Date(this.fullPartObject.viewer3dFile.created).getTime()
      );
      oReq.onloadend = () => {
        this.viewer3dFile = {
          extension: 'blsv',
          buffer: Buffer.from(oReq.response),
        };
      };
    },
  },
};
</script>
