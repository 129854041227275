var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-imageUpdate"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "col": "12"
    }
  }, [_c('span', {
    staticClass: "text-h4"
  }, [_vm._v(" Page mise à jour image par défaut ")]), _c('v-divider', {
    staticClass: "my-4"
  })], 1)], 1), _c('v-card', {
    attrs: {
      "width": "100%"
    }
  }, [_c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_vm.viewer3dFile && _vm.fullPartObject ? _c('Viewer3d', {
    ref: "viewer3dRef",
    attrs: {
      "part-file": _vm.viewer3dFile,
      "information-control-enabled": true,
      "part": _vm.fullPartObject,
      "toolbar": true,
      "clipping": false,
      "reorientation-tool": false,
      "initial-fullscreen": _vm.viewerFullscreen,
      "can-modify-critical-dimensions": false,
      "critical-dimensions-enabled": false,
      "modifications": null,
      "modifications-enabled": false,
      "can-modify-modifications": false,
      "serial-number-enabled": false,
      "can-modify-serial-number": false,
      "picture-tool-enabled": true
    },
    on: {
      "fullscreen": function ($event) {
        _vm.viewerFullscreen = !_vm.viewerFullscreen;
      },
      "saveImage": _vm.addBrandPartImage,
      "renderingReady": _vm.renderingReady
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "orange",
      "width": "100%",
      "disabled": _vm.started
    },
    on: {
      "click": _vm.start
    }
  }, [_vm._v(" Start ")])], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    staticClass: "text-none mr-4",
    attrs: {
      "color": "error",
      "width": "100%",
      "disabled": !_vm.started,
      "loading": _vm.stopAtNext
    },
    on: {
      "click": function ($event) {
        _vm.stopAtNext = true;
      }
    }
  }, [_vm._v(" Stop ")])], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-h6"
  }, [_vm._v(" Pièce en cours ")]), _c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        var _vm$brands, _vm$parts;
        return [_c('thead', [_c('tr', [_c('th', [_vm._v(" Entrepôt ")]), _c('th', [_vm._v(" Pièce ")])])]), _c('tbody', [_c('tr', [_c('td', [_vm.brand ? _c('span', [_vm._v(" " + _vm._s(_vm.brand.name) + " (" + _vm._s(_vm.brandIndex + 1) + "/" + _vm._s((_vm$brands = _vm.brands) === null || _vm$brands === void 0 ? void 0 : _vm$brands.length) + ") ")]) : _vm._e()]), _c('td', [_vm.part ? _c('span', [_vm._v(" " + _vm._s(_vm.part.name) + " (" + _vm._s(_vm.partIndex + 1) + "/" + _vm._s((_vm$parts = _vm.parts) === null || _vm$parts === void 0 ? void 0 : _vm$parts.length) + ") ")]) : _vm._e()])])])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-h6"
  }, [_vm._v(" Image enregistrée de la pièce précédente ")]), _vm.previousPartImageDataURL ? _c('v-img', {
    attrs: {
      "src": _vm.previousPartImageDataURL
    }
  }) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }